import React, { useEffect, useState } from "react";
import "./style.scss";
import Logo from "../../assets/shrinathjikks.png";
import { Link, useLocation } from "react-router-dom";

const menuItems = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Services",
    children: [
      { name: "Jyotish", link: "/services?s=jyotish" },
      { name: "Ayurveda", link: "/services?s=ayurveda" },
      { name: "Vastu", link: "/services?s=vastu" },
      { name: "Yagya", link: "/services?s=yagya" },
    ],
  },
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Services",
    children: [
      { name: "Jyotish", link: "/services?s=jyotish" },
      { name: "Ayurveda", link: "/services?s=ayurveda" },
      { name: "Vastu", link: "/services?s=vastu" },
      { name: "Yagya", link: "/services?s=yagya" },
    ],
  },
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Services",
    children: [
      { name: "Jyotish", link: "/services?s=jyotish" },
      { name: "Ayurveda", link: "/services?s=ayurveda" },
      { name: "Vastu", link: "/services?s=vastu" },
      { name: "Yagya", link: "/services?s=yagya" },
    ],
  },
];

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [secondaryNav, setSecondaryNave] = useState([]);
  const [hasMainBg, setHasMainBg] = useState(undefined);

  const { pathname } = useLocation();

  useEffect(() => {

   setIsNavOpen(false);
   const mainBg = document.getElementsByClassName("main-bg")[0];
   setHasMainBg(mainBg);
  }, [pathname]);

  const openCloseSecondaryNav = (selectedMenuOption) => {
    const secondaryNavClone = [...secondaryNav];
    if (secondaryNav.includes(selectedMenuOption)) {
      const index = secondaryNav.indexOf(selectedMenuOption);

      secondaryNavClone.splice(index, 1);
      setSecondaryNave([...secondaryNavClone]);
    } else {
      setSecondaryNave([...secondaryNav, selectedMenuOption]);
    }
  };

  return (
    <nav className={`nav-wrapper ${hasMainBg ? "!bg-transparent" : ""}`}>
      <div className="logo-wrapper">
        {/* <Link to="/">
          <img src={Logo} />
        </Link> */}
      </div>
      <h1 onClick={() => setIsNavOpen(true)} className="open-menu">
        Menu
      </h1>
      <div className={`menu ${isNavOpen ? "open" : "close"}`}>
        <div className="flex justify-between items-center">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <h1 onClick={() => setIsNavOpen(false)} className="close-menu">
            Close
          </h1>
        </div>
        <div className="menu-content">
          {menuItems.map((menuItem) => (
            <span
              className={`menu-item ${
                secondaryNav.includes(menuItem.name) ? "open" : "close"
              }`}
              onClick={() => setIsNavOpen(false)}
            >
              <Link to={menuItem.link}>{menuItem.name}</Link>
              {menuItem.children && (
                <span
                  className="mobile-arrow"
                  onClick={() => openCloseSecondaryNav(menuItem.name)}
                >
                  <i class="fa fa-angle-down" aria-hidden="true" />
                </span>
              )}
              <div className="sub-menu">
                {menuItem?.children &&
                  menuItem?.children.map((child) => {
                    return (
                      <span className="sub-menu-item">
                        <Link to={child.link} onClick={() => setIsNavOpen(!isNavOpen)}>{child.name}</Link>
                      </span>
                    );
                  })}
              </div>
            </span>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import "./style.scss";
import TestImage from "../../assets/test.png";

const data = [
  {
    id: "ayurveda",
    image: TestImage,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: "vastu",
    image: TestImage,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: "yagya",
    image: TestImage,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    id: "jyotish",
    image: TestImage,
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

const HighlightPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [selectedSection, setSelectedSection] = useState(null);
  const sectionsData = data;

  useEffect(() => {
    window.scrollTo(0, 0)
    const selectedSectionIndex = sectionsData.findIndex(
      (section) => section.id === searchParams.get("s")
    );
    if (selectedSectionIndex >= 0) {
      setSelectedSection(sectionsData[selectedSectionIndex]);
    }
  }, [searchParams.get("s")]);

  // const goToSection = (section) => {
  //   const element = document.getElementsByClassName(section)[0];
  //   element.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //     inline: "nearest",
  //   });
  // };

  return (
    <section className="highlight-section">
      {selectedSection && (
        <div className={`accordion`}>
          <div className="left">
            <img src={selectedSection.image} />
          </div>
          <div className="right">
            <div className="heading-wrapper">
              <h1 className="large-subheading">{selectedSection.id}</h1>
            </div>
            <p>{selectedSection.content}</p>
          </div>
        </div>
      )}

      {/* {sectionsData.map((section) => {
        return section?.id !== selectedSection?.id ? (
          <div className={`accordion other ${section.id}`} onClick={() => {
            setSelectedSection(section)
            window.scrollTo(0, 0)
            // setSearchParams()
          }}>
            <div className="left">
              <img src={section.image} />
            </div>
            <div className="right">
              <div className="heading-wrapper">
                <h1 className="large-subheading">{section.id}</h1>
              </div>
              <p>{section.content}</p>
            </div>
          </div>
        ) : null;
      })} */}
    </section>
  );
};

export default HighlightPage;
